//Import Icon scss
@import "../scss/icons.scss";

//import Light Theme
@import "../scss/bootstrap.scss";
@import "../scss/app.scss";

// RTL mode
// @import "../scss/custom/rtl/bootstrap-rtl";
// @import "../scss/custom/rtl/components-rtl";
// @import "../scss/custom/rtl/float-rtl";
// @import "../scss/custom/rtl/general-rtl";
// @import "../scss/custom/rtl/pages-rtl";
// @import "../scss/custom/rtl/plugins-rtl";
// @import "../scss/custom/rtl/spacing-rtl";
// @import "../scss/custom/rtl/structure-rtl";
// @import "../scss/custom/rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 0px;
  margin: 2em 0;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}

//modal close icon
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}


// calendar
.fc-daygrid-event {
  color: $white !important;
}
.card-columns .card {
  margin-bottom: 24px;
}

@media (min-width: 576px){
  .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
      grid-column-gap: 1.25rem;
      -webkit-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
}
}
.react-bootstrap-table-pagination{
  --bs-gutter-x: 0;
}

// logo
.logo-dark-element{
  display: block;
}

.logo-light-element{
  display: none;
}

[data-bs-theme="dark"]{
  .logo-dark-element{
      display: none;
  }
  
  .logo-light-element{
      display: block;
  }
}

//react select
.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);

    &:hover,
    &:focus {
      border-color: var(--#{$prefix}primary);
    }

    .css-1p3m7a8-multiValue {
      color: var(--#{$prefix}white);
      background-color: var(--#{$prefix}primary);

      .css-wsp0cs-MultiValueGeneric {
        color: var(--#{$prefix}white);
      }

      .css-1u9des2-indicatorSeparator {
        border-color: var(--#{$prefix}border-color);
      }
    }
  }

  :is(.css-qbdosj-Input, .css-1dimb5e-singleValue) {
    color: var(--#{$prefix}body-color);
  }

  .css-t3ipsp-control {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);

    .css-1p3m7a8-multiValue {
      color: var(--#{$prefix}white);
      background-color: var(--#{$prefix}primary);

      .css-wsp0cs-MultiValueGeneric {
        color: var(--#{$prefix}white);
      }

      .css-1u9des2-indicatorSeparator {
        border-color: var(--#{$prefix}border-color);
      }
    }
  }

  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);

    .css-d7l1ni-option {
      color: $white;
      background-color: var(--#{$prefix}primary);

      &:hover,
      &:active,
      &:focus {
        background-color: var(--#{$prefix}primary);
      }
    }
  }
}
//  calendar
.flatpickr-innerContainer {
  border-bottom: var(--#{$prefix}border-color) !important;
}
.flatpickr-calendar {
  border-color: var(--#{$prefix}border-color) !important;
  color: var(--#{$prefix}body-color) !important;
}
.flatpickr-days {
  background-color: var(--#{$prefix}secondary-bg) !important;
  color: var(--#{$prefix}body-color) !important;
  border-color: var(--#{$prefix}border-color) !important;
}
.flatpickr-day.today {
  border-color: var(--#{$prefix}border-color) !important;
  color: var(--#{$prefix}body-color) !important;
  &:hover,
  &:focus {
      border-color: var(--#{$prefix}border-color) !important;
      background: var(--#{$prefix}tertiary-bg) !important;
      color: var(--#{$prefix}body-color) !important;
  }
}
.flatpickr-day {
  color: var(--#{$prefix}body-color) !important;
  &:hover {
      border-color: var(--#{$prefix}border-color) !important;
      background: var(--#{$prefix}tertiary-bg) !important;
      color: var(--#{$prefix}body-color) !important;
  }
}
.dayContainer {
  border-color: var(--#{$prefix}border-color) !important;
}

.chartjs-chart {
  max-height: 300px;
}  

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.apex-charts {
  min-height: 10px !important;
}
// .noti-icon .badge {
//   left: 23px;
// }
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1002 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Crypto enteries
.bs-select select {
  width: auto !important;
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}
//caldendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}

// calendar modal
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.wizard .actions {
  position: relative !important;
  display: block !important;
  text-align: right !important;
  width: 100% !important;
}
.wizard .actions > ul {
  display: block !important;
  text-align: right !important;
  padding-left: 0 !important;
}
.wizard .actions > ul > li {
  display: inline-block !important;
  margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}
.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}
.btn-active {
  background: #8bc34a !important;
  color: white !important;
  padding: 6px 21px;
}
.btn-deactive {
  color: white !important;
  background: #ec536c !important;
}
.Toastify__toast-body > div:last-child {
  flex: 1 1;
  text-transform: capitalize;
}

.text-form .form-control {
  float: right;
  margin-bottom: 1rem;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0px;
  border-top-width: 1px;
  text-align: start;
}
.table tbody td img {
  border-radius: 5px;
  border: 1px solid #ddd;
}
.table tbody td img {
  font-size: 14px;
}

.usercard {
  margin: 0px !important;
  background-color: #6e1ced !important;
  box-shadow: 10px 5px 5px #bbb3b3 !important;
  position: relative;
  z-index: 10;
}

.user {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0.5rem 1rem;
}

.profile img {
  // border-radius: 10px;
  width: 50px;
}

.userinfo p {
  margin: 0px;
  padding: 0px;
  color: white;
}

.usertab {
  display: flex;
  align-items: center;
  gap: 8px;
  // margin-left: 17px;
  // margin-right: 17px;
  background: white;
}

.cardheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 1.5rem;
}

.cardheader span {
  color: #556ee6;
  font-size: 1rem;
}

.createdcard {
  box-shadow: 0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%) !important;
}

.cardprofile {
  border-radius: 50px;
  width: 50px;
  display: flex;
  margin: 0.5rem 2rem;
}

.cardprofile img {
  border-radius: 50px;
  width: 35px;
  margin-right: 3px;
}

.cardfooter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0rem;
  gap: 1rem;
  margin-left: 2rem !important;
}

.footerimg img {
  border-radius: 15px;
  width: 100px;
}

.footerbtn {
  display: flex;
  gap: 2rem;
}
.usertab li {
  // background-color: white;
  background-color: #e7e7e9;
  width: 150px;
  height: 39px;
}

.usertab li a {
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 26px;
  // color: rgb(122 111 190);
  color: #615f5f;
  // font-weight: 600;
  margin-top: 7px;
  font-weight: 100;
  text-transform: capitalize;
}

.usertab {
  .active {
    background-color: #6e1ced !important;
    // box-shadow: 10px 5px 5px #3b3b3b !important;
    color: white !important;
    // border-radius: 10px!important;
    width: 150px;
    height: 50px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  .active a {
    color: white !important;
    margin-top: 7px;
    font-weight: 100;
  }
}
.cardactivity h1 {
  font-size: 20px;
  margin-bottom: 6px;
}

.type-user {
  font-size: 18px !important;
  color: #6e1ced;
}

.adminmem span {
  color: white !important;
}
.adminmem {
  background: #6e1ced;
  color: white !important;
  padding: 1px 6px;
  border-radius: 5px;
}
.rightbtn {
  float: right !important;
  margin-left: 4.3rem !important;
}
.sortable {
  text-align: center !important;
}
.active-user img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

#exTab1 .tab-content {
  color: white !important;
  background-color: #ebebed !important;
  padding: 13px 11px !important;
}

#exTab1 {
  background: #ebebed;
  margin-top: -0.5rem;
  padding: 0;
}
#exTab2 h3 {
  color: white !important;
  background-color: #ebebed !important;
  padding: 5px 15px !important;
}

/* remove border radius for the tab */

#exTab1 .nav-pills > li > a {
  border-radius: 0;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills > li > a {
  border-radius: 4px 4px 0 0 !important;
}

#exTab3 .tab-content {
  color: white !important;
  background-color: #428bca !important;
  padding: 5px 15px !important;
}

.btnbox {
  background-color: white;
  border: 1px solid black !important;
  // box-shadow: 3px 4px 5px rgb(255, 154, 3);
  margin: 5px;
}
.btnbox:hover {
  border: 1px solid black !important;
}

.mystyle {
  background-color: #556ee6 !important;
  color: white !important;
}
.defaulbtn {
  color: #428bca;
}
.text-label {
  line-height: 1.5;
  margin-right: 8px;
}

.filter-item {
  display: inline-flex;
  width: 72.5%;
  justify-content: flex-end;
}
.selected {
  background-color: #6e1ced !important;
  color: white !important;
  border-color: #6e1ced !important;
}

.btn-design button {
  background-color: white;
  border: 1px solid black !important;
  border-radius: 3rem !important;
  margin: 5px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  color: #5b626b;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.7rem;
  font-size: 0.8125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-design button img {
  width: 23px;
  margin-right: 5px;
  border-radius: 2px;
}
.btnw {
  width: 10% !important;
}
.userprofile img {
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 53px;
  margin: auto;
  display: block;
}
.iconview {
  font-size: 20px;
}
.userdetails {
  display: flex;
}
.fst p {
  color: black;
}
.fst p span {
  color: #6e1ced;
}
.userinfo1 {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.userinfo2 {
  width: 77%;
  display: flex;
  justify-content: space-between;
}
.fst h3 {
  text-transform: capitalize;
}
iframe {
  display: none;
}
.logo-1 {
  position: absolute;
  width: 103px;
  left: 4%;
  top: -1px;
  border-radius: 5px;
}
.react-bootstrap-table table {
  table-layout: auto;
}
.cardactivity h5 {
  color: #5e4ccd;
  text-transform: capitalize;
}

.usertab .active {
  box-shadow: none !important;
  height: 39px !important;
}
.navbar-brand-box {
  padding: 1rem 1.5rem !important;
  text-align: center;
  width: 240px;
}
.table-center th,
.table-center td,
.table th,
.table td {
  text-align: center !important;
}
.overflow-x .react-bootstrap-table {
  overflow-x: scroll !important;
}
.overflow-x .pagination {
  margin-top: 1rem;
}

.bgcard {
  //background-image: url(https://img.freepik.com/premium-photo/grunge-asphalt-road-surface-with-city_163855-164.jpg);
  padding: 25px 20px;
  border-radius: 10px;
}
.bgcard input,
.bgcard select,
.bgcard textarea {
  background-color: transparent !important;
  color: white !important;
  border: none;
  border-bottom: 1px solid #ffffff5e;
  border-radius: 1px;
}
.bgcard .form-select {
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-bottom: 1px solid #ffffff5e;
  border-radius: 1px;
}

.bgcard input::placeholder,
.bgcard textarea::placeholder {
  color: white !important;
}
.bgcard input,
.bgcard select option {
  background-color: #2b3a4a;
}
.subtitle {
  font-size: 40px;
}
.badge {
  margin-top: -1rem;
  margin-bottom: -2rem;
  text-align: end;
}
.badge img {
  position: absolute;
  right: 74px;
  z-index: 10000;
  top: 17px;
}
.badge input {
  width: 93px;
  padding-left: 1rem !important;
  position: absolute;
  height: 33px;
  background: linear-gradient(77.55deg, #78de13 12.82%, #c5ee20 76.53%);
  border-radius: 11px;
  outline: none;
  font-size: 21px;
  border: none;
  padding: 8px;
  right: 8px;
  z-index: 100;
}
.bgcard .away {
  font-size: 10px;
  color: #fff;
  margin-left: 1rem;
}
.bgcard .custom-react-select .css-1uccc91-singleValue {
  color: #fff !important;
}
.w-50 {
  width: 50%;
}
.btn-red {
  background: #f44336 !important;
  border-color: #f44336 !important;
  position: absolute;
  width: auto;
  bottom: 40px;
  right: 3%;
}

.admore {
  position: absolute;
  right: 3%;
  bottom: 68px;
}

.relativerow {
  position: relative;
  margin-bottom: 2rem;
}

.select--1 {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff !important;
  text-shadow: 0px 3.4026px 8.50649px rgba(0, 0, 0, 0.75);
  margin-top: 2rem;
}
.custom-react-select .css-yk16xz-control,
.custom-react-select .css-1pahdxg-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #79828a;
  border-radius: 0;
  outline: none !important;
}

.custom-react-select .css-1wa3eu0-placeholder,
.custom-react-select .css-1uccc91-singleValue {
  color: hsl(0deg 0% 100%);
}
.custom-react-select .css-1pahdxg-control:hover {
  outline: none !important;
  border-color: hsl(0deg 0% 100%) !important;
}
.custom-react-select .css-1wa3eu0-placeholder,
.custom-react-select .css-1uccc91-singleValue {
  color: hsl(0deg 3.11% 22.91%);
}

.box-custom {
  background-image: url(https://img.freepik.com/premium-photo/grunge-asphalt-road-surface-with-city_163855-164.jpg);
  padding: 2px 18px;
  border-radius: 10px;
  color: white;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.box-custom2 {
  padding: 2px 18px;
  border-radius: 10px;
  color: white;
  background-color: #d6d6d6;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 30px !important;
  //background: #121117;
  //background: linear-gradient(180deg, #bf007e 1%, rgba(64, 60, 37, 0.9) 45%);
}

.bottom-footer {
  margin-top: 8rem;
}

.bottom-footer .logo img {
  width: 43px;
  border-radius: 50px;
}
.bottom-footer .logo {
  width: 64px;
  height: 62px;
  display: flex;
  background: transparent;
  margin-right: 10px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}

.box-custom .badge input {
  width: 78px;
  margin-right: 1rem;
  // padding-left: 2rem !important;
}
.box-custom .badge {
  margin-top: 0rem;
  margin-bottom: 1rem;
  text-align: end;
}
.box-custom::after {
  content: "";
  background: #00000085;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
}

// .box-custom[style="border: 1px solid rgb(204, 204, 204); background-image: url("undefined"); background-repeat: no-repeat; background-size: cover;"]
// {
//   background-image: url(https://img.freepik.com/premium-photo/grunge-asphalt-road-surface-with-city_163855-164.jpg);
// }
.fnt-18 {
  font-size: 18px;
}
.box-custom .bottom-footer h3 {
  font-size: 19px;
  margin-bottom: 2px;
  margin-top: 4px;
}
.modal-custom-button {
  background: rebeccapurple;
  padding: 1rem 2rem;
  border-radius: 5px;
  color: white;
  font-size: 30px;
}

.btn-saves {
  width: 22%;
  margin-bottom: -2rem;
  text-align: -webkit-center;
  height: 44px;
}
.btn-remove {
  background: #ec536c;
  color: white;
  padding: 6px 13px;
  border-radius: 5px;
  margin-top: 9px;
}
.row.align-items-center .col-lg-4:first-child {
  text-align: end;
}
.fix-height {
  height: 256px;
  display: flex;
  align-items: flex-end;
}
.box-custom .dic {
  margin-top: 8rem;
}
.limit-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.btn-primary-edit {
  background: rebeccapurple;
  padding: 5px 12px;
  color: white;
  border-radius: 5px;
  margin-left: 0.5rem;
}
.ht-480 .subtitle {
  overflow: hidden;
  height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ht-480 {
  height: 480px;
}

.modal-deal {
  min-width: 80vw !important;
}

.ticket {
  margin-top: 30px;
  display: flex;
  background: linear-gradient(270deg, #f300e9 30%, #d80074 100%);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.ticket .divider {
  width: 2px;
  height: calc(100% - 32px); /* Subtract twice the radius of the circles */
  background: #0a192f;
  margin: 0 auto;
}

.ticket::before {
  content: "";
  position: absolute;
  left: 30%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background: #d6d6d6;
  border-radius: 50%;
}
.ticket::after {
  content: "";
  position: absolute;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  top: 100%;
  background: #d6d6d6;
  border-radius: 50%;
}

.ticket::before {
  border-right: 1px dashed #fff;
  right: 70%;
}

.ticket .section.left {
  width: 30%;
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  border-right: 1px dashed #fff;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
}

.ticket .section.right {
  width: 70%;
  text-align: left !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

// .ticket::after {
//   border-left: 2px dashed #0a192f;
//   left: 50%;
// }

.ticket .section {
  padding: 20px;
  box-sizing: border-box;
  color: white;
}

.ticket .section.left {
  text-align: left;
}

.ticket .section.right {
  text-align: right;
}

.countdown {
  margin-left: -20px;
  width: 280px !important;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.countdown div {
  padding: 8px 10px;
  padding-bottom: 0 !important;
  text-align: center;
  background-color: rgba(71, 71, 72, 0.6); /* #474748 converted to rgba */
  border-radius: 5px;
  margin: 2px;
}

.red-border {
  border: 3px solid !important;
  border-image-source: linear-gradient(
    208.86deg,
    #d24c00 1.43%,
    #96005a 101.97%
  ) !important;
  border-image-slice: 1 !important;
  border-radius: 12px !important;
}

.deal-footer {
  backdrop-filter: blur(35px);
  align-items: center !important;
  margin-left: -20px;
  margin-bottom: -2px;
  width: 115%;
}

.ticket {
  margin-top: 30px;
  display: flex;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

// .ticket::after {
//   border-left: 2px dashed #0a192f;
//   left: 50%;
// }

.card2 {
  width: 100%;
  background: rgba(255, 255, 255, 0.2); /* adjust as needed for glass effect */
  backdrop-filter: blur(10px); /* adjust as needed for glass effect */
  border-radius: 12px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.card2 > .badge {
  background: linear-gradient(
    263.77deg,
    #f30092 11.65%,
    #c51272 49.33%,
    #9026e3 91.92%
  );
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.card2 > .title {
  /* add your styles for the title here */
}

.card2 > .description {
  /* add your styles for the description here */
}

.card2 > .footer {
  /* add your styles for the footer here */
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.card2 > .top-right {
  background: #e6007e;
  border-radius: 0px 11px 1px 0px;
  width: 50px; /* adjust as needed */
  height: 50px; /* adjust as needed */
  position: absolute;
  top: 20px;
  right: 20px;
}

.badge-deal {
  padding: 8px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  position: absolute;
  top: 0;
  right: 0 !important;
  height: 33px;
  background: #e6007e;
  border-radius: 0px 0px 0px 6px !important;
  outline: none;
  font-size: 21px;
  right: 8px;
  z-index: 100;
  color: white;
  font-size: 12px !important;
}

// .badge-deal::before,
// .badge-deal::after {
//   content: "";
//   position: absolute;
//   border-style: solid;
//   border-width: 3px;
//   border-color: #e6007e; /* replace with your border color */
// }

// .badge-deal::before {
//   top: 0;
//   right: 0;
//   border-left-color: transparent;
//   border-bottom-color: transparent;
//   width: 20px; /* replace with your desired border size */
//   height: 20px; /* replace with your desired border size */
// }

// .badge-deal::after {
//   bottom: 0;
//   left: 0;
//   border-right-color: transparent;
//   border-top-color: transparent;
//   width: 20px; /* replace with your desired border size */
//   height: 20px; /* replace with your desired border size */
// }
.customcomponent .bottom-footer .logo {
  width: 64px;
  border-radius: 50px !important;
}
.customcomponent .badge {
  margin-top: 0rem;
  margin-bottom: 1rem;
  text-align: end;
  position: absolute;
  right: -26px;
  top: -8px;
  background-color: #e6007e;
}
.customcomponent .badge input {
  width: 65px !important;
  padding-left: 0.5rem !important;
  position: absolute;
  height: 32px;
  border-radius: 3px 5px 2px 20px;
  outline: none;
  font-size: 16px;
  border: none;
  background: #e6007e !important;
  padding: 8px;
  right: 8px;
  z-index: 100;
  text-align: center;
}
.fix-box-preview {
  height: 323px !important;
}
.fix-box-preview h6.fnt-18 {
  height: 45px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fix-box-preview h5.title {
  height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fix-box-preview .bottom-footer {
  margin-top: 8rem;
  height: 100px;
}

.fix-box-preview .dic {
  margin-top: 7rem;
}

.selected-row-class {
  background-color: #c8e6c9 !important;
}

.table-custom-striped tr:nth-child(even) {
  background-color: red; /* or any color you like */
}

.table-custom-border,
.table-custom-border thead tr,
.table-custom-border thead tr th,
.table-custom-border tbody tr,
.table-custom-border tbody tr td {
    background-color: white; /* Set background color to white */
}

.table-shorting.card-body{
  padding-right: 0px;
  padding-left: 0px;    
}

.table-shorting.card-body  .col-lg-12:first-child {
  padding-right: 32px;
  padding-left: 32px; 
}

.table-custom-border{
  border-bottom: 1px solid #e0e0e0; /* Add border to bottom of each row */
}

.admin-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.admin-card-content {
  flex-grow: 1;
}

.admin-card-image img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 24px;
}

div.ReactModal__Overlay {
  z-index: 2000 !important;
}

// .react-bootstrap-table {
//   overflow: scroll;
// }
.custom-overflow-hidden {
  overflow: hidden !important;
}
// .custom-card .react-bootstrap-table {
//   overflow: hidden !important;
// }

.video-list .row
{
  align-items: center;
  gap: 5px;
}
.video-list .card-video
{
  background: whitesmoke;
  border-radius: 5px;
  padding: 8px;
}
.dragging
{
  background-color: rgb(228, 223, 223) !important;
}


.react-bootstrap-table{
  overflow-x: auto !important;
}





/* For screen sizes less than or equal to 640px */
@media (max-width: 640px) {
  .filter-screen-mobile {
    display: block !important; /* Show on mobile devices */
  }

  .filter-screen-medium {
    display: none !important; /* Hide on medium-sized devices */
  }

  .filter-screen-desktop {
    display: none !important; /* Hide on desktop devices */
  }
}

/* For screen sizes between 641px and 1200px */
@media (min-width: 641px) and (max-width: 1200px) {

  .filter-screen-mobile {
    display: none !important; /* Hide on mobile devices */
  }

  .filter-screen-medium {
    display: block !important; /* Show on medium-sized devices */
  }

  .filter-screen-desktop {
    display: none !important; /* Hide on desktop devices */
  }
}

/* For screen sizes greater than or equal to 1201px */
@media (min-width: 1201px) {

  .filter-screen-mobile {
    display: none !important; /* Hide on mobile devices */
  }

  .filter-screen-medium {
    display: none !important; /* Hide on medium-sized devices */
  }

  .filter-screen-desktop {
    display: flex !important; /* Show on desktop devices */
  }
}


.filter-screen-desktop{
  width: -moz-available; /* Firefox */
  width: -webkit-fill-available; /* Chrome, Safari */
  width: fill-available; /* Standard */
}


.navbar .navbar-logo {   
  height: 100px !important;
}

.filter-screen-mobile .filter-item{
  width: -moz-available; /* Firefox */
  width: -webkit-fill-available; /* Chrome, Safari */
  width: fill-available; /* Standard */
}

.filter-screen-mobile .form-select{
  font-size: 11px;
  padding: 0 !important;
  height: 40px;
  font-size: 12PX;
}

