:root {
  --primary-color: #5E18C9;
  --sec-color: #5E18C9;
  --tab-active-color: #5E18C9;
}

.custom-card {
  position: relative;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08) !important;
  border: none;
  width: 80vw !important;
  padding: 0 !important;
  margin: 0 auto;
}

.cover-image {
  width: 100%;
  height: 300px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-image {
  position: absolute;
  top: 248px;
  left: 45%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-image2 {
  position: absolute;
  top: 160px;
  left: 20px;
  width: 80px;
  height: 80px;
  border: 3px solid white;
  border-radius: 50%;
}

.profile-image2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.tab-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;  
  border-radius: 5px;
}

.tab.active {
  background: var(--tab-active-color);
  color: white;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f1f1f1;
}


.tabs {
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
  padding: 8px;
  border-radius: 12px;
  background: #fff !important;
  margin-left: 24px;
  width: 80vw !important;
}