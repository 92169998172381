.custom-card {
    position: relative;
  }
  
  .cover-image {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .profile-image {
    position: absolute;
    top: 248px;
    left: 45%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  }
  
  .profile-image2 {
    position: absolute;
    top: 160px;
    left:20px;
    width: 80px;
    height: 80px;
    border: 3px solid white;
    border-radius: 50%;
    }
    
.profile-image2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}


.twitter-like-tabs2 {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #657786; 
}

.twitter-like-tabs2 > ul {
    display: flex;
    justify-content: space-between;
    margin: 0 30%;
  }

.twitter-like-tabs2 .nav-pills {
    border-bottom: 1px solid #e1e8ed;
}



.twitter-like-tabs2 .tab-content > .tab-pane {
    background-color: transparent !important;   
    color: #657786;
}


#exTab1.twitter-like-tabs2 .tab-content {
    background-color: transparent !important;  
    padding: 20px !important; 
    border-radius: 0 0 10px 10px !important;    
}



.twitter-like-tabs2 .nav-pills > li {
    margin-right: 20px;
    flex-grow: 1;
    text-align: center;
    padding: 10px;
}

.twitter-like-tabs2 .nav-pills > li > a {
    border-radius: 0;
    font-size: 16px;
    color: #657786;
    background-color: transparent;
    border-bottom: 2px solid transparent;
}

.twitter-like-tabs2 .nav-pills > li.active > a,
.twitter-like-tabs2 .nav-pills > li > a:hover {
    color: #5e18c9;
    border-bottom-color: #5e18c9;
}

.twitter-like-tabs2 .nav-pills > li > a:hover {
    background-color: #e8f5fe; /* Adjust color as needed */
}

.padded-tab {
    padding: 15px;
    min-height: 200px;
  }