//
// authentication.scss
//

.auth-body-bg {
    background-color: $card-bg;
}

.authentication-bg {
    // background-image: url("https://www.whosin.me/wp-content/uploads/2024/02/whosin_dubai_app_download_now-scaled.jpg?id=10601");
     height: 100vh;
    background-size: cover;
    background-position: center;
    .bg-overlay {
        background-color: rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 991px) {
        display: none;
    }
}

.authentication-page-content {
    height: 100vh;
    display: flex;
}

.auth-form-group-custom {
    position: relative;
    .form-control {
        height: 60px;
        padding-top: 28px;
        padding-left: 60px;
    }

    label {
        position: absolute;
        top: 7px;
        left: 60px;
    }

    .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $primary;
    }
}

.auth-logo {
    &.logo-light {
        display: $display-none;
    }
    &.logo-dark {
        display: $display-block;
    }
}

[data-bs-theme="dark"] {
    .authentication-logo {
        .logo-light {
            display: block;
        }
        .logo-dark {
            display: none;
        }
    }
}
