:root {
  --primary-color: #5e18c9;
  --sec-color: #5e18c9;
  --tab-active-color: #5e18c9;
}

.custom-card {
  position: relative;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08) !important;
  border: none;
  width: 80vw !important;
  padding: 0 !important;
  margin: 0 auto;
}

.cover-image {
  width: 100%;
  height: 300px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-image {
  position: absolute;
  top: 248px;
  left: 45%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-image2 {
  position: absolute;
  top: 160px;
  left: 20px;
  width: 80px;
  height: 80px;
  border: 3px solid white;
  border-radius: 50%;
}

.profile-image2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* The main container for the tabs */
.tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* center the tab block on large screens */
  margin-top: 16px;
}

/* The row holding all tabs */
.tab-container .tabs {
  display: flex;
  flex-wrap: wrap; /* let them wrap if needed on larger screens too */
  gap: 8px;        /* space between tabs */
  background: #fff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 8px;
  border-radius: 12px;
  width: 80vw !important;
  margin-left: 0;
}

/* Individual tab style */
.tab {
  padding: 10px 20px;
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
  text-align: center;
  background: #f5f5f5;
  /* Use flex: 0 auto if you want them to remain minimal width;
     or remove flex if you prefer each tab to shrink/expand as needed */
}

.tab.active {
  border-bottom: 2px solid #000;
  font-weight: bold;
  background: var(--tab-active-color);
  color: white;
}

/* Hide the dropdown by default */
.dropdown-menu {
  display: none;
  position: absolute;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f1f1f1;
}

/* 
   Media query: On smaller devices, stack the tabs vertically
   and remove any scroll-based properties.
*/
@media (max-width: 768px) {
  .tab-container {
    flex-direction: column; /* stack everything */
    align-items: center;    /* center them in the column */
  }

  .tab-container .tabs {
    display: flex;
    flex-direction: column; /* vertical stacking of tabs */
    width: 100% !important; /* full width on small devices */
    gap: 4px;              /* less gap for stacked tabs */
    border-radius: 0;      /* optional: remove radius for a cleaner stack */
    box-shadow: none;      /* optional: remove box shadow on small screens */
    padding: 0;
  }

  .tab {
    width: 100%;  /* full width for each tab */
    margin: 0;    /* no horizontal margin needed */
    border-radius: 0;
    text-align: left; /* optional: left align text on small devices */
  }

  .tab.active {
    /* On mobile, you can highlight differently if you want */
    border-bottom: none;
    background: var(--tab-active-color);
    color: white;
  }
}
